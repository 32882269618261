
import FormInput2 from '@/components/forms/FormInput2.vue'
import { environment } from '@/helpers/Environment'
import type { LanguageStrings } from '@/language/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { openTermsModal } from '@/modals/termsModal'

@Component({
  name: 'TermsCheckbox',
  components: {
    FormInput2,
  },
})
export default class extends Vue {
  @Prop({ default: false })
  value: boolean

  viewingIsRequired = !environment.web.terms_and_conditions?.viewing_is_optional

  t: LanguageStrings['termsCheckbox']

  mounted() {
    this.initializeClickHandler()
  }

  get internalValue(): boolean {
    return this.value
  }

  set internalValue(v: boolean) {
    this.$emit('input', v)
  }

  initializeClickHandler() {
    const wrapper = (this.$refs.checkbox as Vue).$el
    wrapper.addEventListener('click', (e: Event) => {
      if (this.clickShouldShowTermsModal(e)) {
        // Uncheck the terms and conditions checkbox.
        this.internalValue = false
        // Ignore declined terms and conditions if opened via checkbox.
        this.openTermsModal().catch(() => null)
      }
    })
  }

  clickShouldShowTermsModal(event: Event) {
    if (this.viewingIsRequired) {
      return true
    } else {
      const el = event.target as HTMLElement
      return 'a' === el.tagName.toLowerCase()
    }
  }

  get checkboxLabel() {
    return this.wrapLabelInLink ? `<a>${this.t.checkboxLabel}</a>` : this.t.checkboxLabel
  }

  get wrapLabelInLink() {
    if (this.viewingIsRequired) {
      return false
    } else {
      // Viewing terms & conditions is optional.
      // Ensure a link exists to view them even if the label language is overridden and omits an <a> tag.
      const el = document.createElement('div')
      el.innerHTML = this.t.checkboxLabel
      return el.querySelector('a') === null
    }
  }

  validate(): Promise<void> {
    if (!this.internalValue) {
      // Open the terms dialog if they have not been accepted yet.
      return this.openTermsModal()
    } else {
      return Promise.resolve()
    }
  }

  openTermsModal(): Promise<void> {
    return new Promise((resolve, reject) => {
      openTermsModal(this.$t('termsModal.title') as string, (data) => {
        this.internalValue = data?.accepted
        if (data?.accepted) {
          /* eslint-disable no-console */
          console.log('Accepted terms and conditions')
          resolve()
        } else {
          reject({ validationError: true })
        }
      })
    })
  }
}

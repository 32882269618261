import { API } from '@/api/API'
import { cartApiParams } from '@/api/Cart'
import type { PaymentPayload, StripePayload } from '@/api/types/payloads'
import {
  completedOrdersRequestEmbeds,
  CompletedOrdersResponse,
  PartialPaymentEntities,
  PartialPaymentResponse,
} from '@/checkout/helpers/completing'
import TixStripeError from '@/checkout/stripe/TixStripeError'
import { ConfirmPaymentApiErrorEntity } from '@/errors/helpers'
import { isLoggedIn } from '@/state/Authentication'
import type { Cart } from '@/store/Cart'
import store from '@/store/store'
import type { Stripe, StripeElements } from '@stripe/stripe-js'
import type { ConfirmPaymentData } from '@stripe/stripe-js/types/stripe-js/payment-intents'
import type { AxiosError } from 'axios'

export interface CheckoutOptions {
  tokens: PaymentTokens
  // TODO Better name for this scenario?
  injected: boolean
  suppressEmailNotification?: boolean
  purchaser?: string
  giftee?: string
}

export interface PaymentTokens {
  stripe?: string
  captcha?: string
}

export interface CompletedCheckoutResult {
  status: 'completed'
  orders: CompletedOrdersResponse
}

export interface PendingCheckoutResult {
  status: 'pending'
  cart: Cart
}

export interface PartialPaymentResult {
  status: 'partial'
  cart: PartialPaymentResponse
}

export type CheckoutResult = CompletedCheckoutResult | PendingCheckoutResult | PartialPaymentResult

interface CheckoutParameters {
  cart: CartEntity
  stripe: Stripe
  elements?: StripeElements
  confirmPaymentData?: ConfirmPaymentData
  options: CheckoutOptions
}

export async function processPaymentPayloads(
  cart: CartEntity,
  stripe: Stripe,
  payloads: PaymentPayload[],
  options: CheckoutOptions,
  elements?: StripeElements,
  confirmPaymentData?: ConfirmPaymentData,
): Promise<CheckoutResult> {
  if (payloads.length < 1) {
    throw new Error('Invalid payments array; At least one payload is required to make a payment')
  }

  const params: CheckoutParameters = { cart, stripe, options, elements, confirmPaymentData }

  const copiedPaylods = [...payloads]
  const last = copiedPaylods.pop()!
  await processFirstNPayments(copiedPaylods, params)
  return await processLastPayment(last, params)
}

async function processFirstNPayments(payloads: PaymentPayload[], options: CheckoutParameters): Promise<void> {
  for (const payload of payloads) {
    const { cart } = await processPartialPayment(payload, options)
    await store.dispatch('Cart/injectResponse', cart)
  }
}

async function processLastPayment(payment: PaymentPayload, options: CheckoutParameters): Promise<CheckoutResult> {
  const amount = payment.gateway_id === 'free' ? 0 : Number(payment.amount)
  const due = store.getters['Cart/paymentDue']

  if (amount < due) {
    return await processPartialPayment(payment, options)
  } else {
    return await processCheckout(payment, options)
  }
}

function processPartialPayment(payload: PaymentPayload, options: CheckoutParameters) {
  // TODO `pay` api rejects a payload with `captcha` property. Remove this once `captcha`
  // is enabled for that endpoint or it is removed altogether.
  const copy = { ...payload, captcha: undefined }

  return partialPay(options.cart.id, copy).catch((error) => {
    return handleRequiredPaymentIntent(error, payload, options).then((newPayload) => {
      return partialPay(options.cart.id, newPayload)
    })
  })
}

async function partialPay(id: string, body: PaymentPayload): Promise<PartialPaymentResult> {
  const endpoint = isLoggedIn() ? `my/cart/${id}/pay` : `cart/${id}/pay`
  const { query, embed } = cartApiParams()
  const response = await API.post<PartialPaymentEntities>(endpoint, { body, query, embed })
  return {
    status: 'partial',
    cart: response,
  }
}

function processCheckout(payload: PaymentPayload, options: CheckoutParameters): Promise<CheckoutResult> {
  return checkout(options.cart.id, payload, options.options).catch((error) => {
    return handleRequiredPaymentIntent(error, payload, options).then((newPayload) => {
      return checkout(options.cart.id, newPayload, options.options)
    })
  })
}

export function checkout(id: string, payment: PaymentPayload, options: CheckoutOptions) {
  if (options.injected) {
    return checkoutInjectedCart(id, payment, options)
  } else if (isLoggedIn()) {
    return checkoutAuthenticatedCart(id, payment, options.giftee)
  } else {
    return checkoutGuestCart(id, payment, options.purchaser!, options.giftee)
  }
}

function checkoutInjectedCart(id: string, payment: PaymentPayload, options: CheckoutOptions) {
  // No `my/` prefix.
  return post(`cart/${id}/guest_checkout`, payment, { send_email: !options.suppressEmailNotification })
}

function checkoutGuestCart(id: string, payment: PaymentPayload, purchaserId: string, gifteeId?: string) {
  const path = `my/cart/${id}/guest_checkout`
  if (!gifteeId) {
    return post(path, payment, { guest_identity_id: purchaserId })
  } else {
    // A gift recipient is the guest identity.
    return post(path, payment, { guest_identity_id: gifteeId, giver_identity_id: purchaserId })
  }
}

function checkoutAuthenticatedCart(id: string, payment: PaymentPayload, gifteeId?: string) {
  if (!gifteeId) {
    return post(`my/cart/${id}/checkout`, payment)
  } else {
    return post(`my/cart/${id}/gift_checkout`, payment, { giftee_identity_id: gifteeId })
  }
}

function handleRequiredPaymentIntent(
  error: AxiosError<ApiResponseItem<ConfirmPaymentApiErrorEntity>>,
  payload: PaymentPayload,
  { stripe, elements, confirmPaymentData }: CheckoutParameters,
): Promise<PaymentPayload> {
  if (error.response) {
    const { status, data } = error.response
    if (status === 402 && data._data[0]._code === 'intent_requires_action') {
      return stripe
        .confirmPayment({
          elements,
          clientSecret: data._data[0]._extra.client_secret,
          confirmParams: confirmPaymentData,
          redirect: 'if_required',
        })
        .then((result) => {
          if (result.paymentIntent) {
            const newPayload = { ...payload } as StripePayload
            newPayload.gateway_data.source = result.paymentIntent.id
            return newPayload
          } else {
            throw new TixStripeError(result.error)
          }
        })
    }
  }

  // Rethrow any other errors.
  throw error
}

function post(path: string, payment: PaymentPayload, identities: CheckoutAPIIdentities = {}): Promise<CheckoutResult> {
  const body = { ...payment, ...identities }
  // TODO Optimize: Only fetch the ticket_order, seller, and seller.meta.
  // Pass the cart and purchaser email address from <CheckoutRoute> to <CompletedOrdersRoute>.
  const embed = ['ticket_order', ...completedOrdersRequestEmbeds]
  return API.post(path, { body, embed }).then((response) => {
    // TODO Figure out a way to do better type support for this or have the API return a more expected response.
    // @ts-ignore Response can be `{ _data: { _code: 'intent_processing' }[] }` for BACS checkout.
    if ('_data' in response && response?._data[0]._code === 'intent_processing') {
      return {
        status: 'pending',
        cart: store.getters['Cart/cart'],
      }
    } else {
      return {
        status: 'completed',
        orders: response,
      }
    }
  })
}

type CheckoutAPIIdentities = {} | AnonymousCheckoutIdentities | AuthenticatedGiftedCheckoutIdentities

interface AnonymousCheckoutIdentities {
  guest_identity_id: string
  giver_identity_id?: string
}

interface AuthenticatedGiftedCheckoutIdentities {
  giftee_identity_id: string
}
